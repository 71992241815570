import { Button } from "antd";
import { usePageData } from "../hooks/usePage";
import { IPageData } from "../interfaces/page";
import { BlobPDFNeurologia } from "../pages/history/Neurologia/PrintNeurologia";
import { BlobFormularioPDF } from "../APPValDiscapacidad/ui/formulario/CreateFormularioPDF";
import { BlobExpedienteNewPDF } from "../pages/history/print/PrintNewBody";
import { BlobExpedientePage2 } from "../pages/history/print/PrintExpPage2";
import { InitPDF } from "./CreatePDF";

const pageData: IPageData = {
    title: 'Prueba PDF',
    fulFilled: true,
    breadcrumbs: [
        {
            title: 'Principal',
            route: 'default-dashboard'
        },
        {
            title: 'Pdf viewer'
        }
    ]
};

const DrawPDF = async (
    OPT: 'NEURO' | 'FORMULARIO_STUDENT' | 'EXPEDIENTE' | 'EXP_PAGE2' | 'APPOINTMENT', data: any
) => {

    let _pdf;
    if (OPT == 'FORMULARIO_STUDENT') {
        _pdf = await BlobFormularioPDF(data);
    } else if (OPT == 'EXPEDIENTE') {
        _pdf = await BlobExpedienteNewPDF(data)
    } else if (OPT == 'EXP_PAGE2') {
        _pdf = await BlobExpedientePage2(data)
    } else if (OPT == 'APPOINTMENT') {
        _pdf = await InitPDF(data)
    } else { // NEUROLOGIA
        _pdf = await BlobPDFNeurologia([], {} as any)
    }
    const bPdf = URL.createObjectURL(_pdf);

    const elmCont = document.getElementById("embed-pdf-container") as HTMLEmbedElement
    elmCont.src = bPdf
}

var isInitialized: boolean = true;

const PDFViewerTest = (
    { OPT, data }: { OPT: 'NEURO' | 'FORMULARIO_STUDENT' | 'EXPEDIENTE' | 'EXP_PAGE2' | 'APPOINTMENT', data: any }
) => {
    usePageData(pageData);

    if (isInitialized)
        DrawPDF(OPT, data);
    isInitialized = false;

    return (
        <>
            <div className='pdf-container-test'>
                <embed id="embed-pdf-container" width="100%" height="800px" type="application/pdf"></embed>
            </div>
            <Button type='primary' className='ml-auto'>
                Guardar
            </Button>
        </>
    )
}

export default PDFViewerTest;