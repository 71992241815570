import { useState } from 'react';

import { Button, Modal } from 'antd';

import EditAppointment from './EditAppointment';
import AddAppointment from './AddAppointment';
import AppointmentsTable from './AppointmentsTable';

import PageAction from '../../layout/components/page-action/PageAction';
import { usePageData } from '../../hooks/usePage';

import { IAppointment } from '../../interfaces/patient';
import { IPageData } from '../../interfaces/page';
import { CreateAppointment, LoadAppointments, RemoveAppointment, UpdateAppointment } from '../../data/Appointment';
import { LoadPatients } from '../../data/Patient';
import { useNavigate } from 'react-router-dom';
import openNotification from '../Toast';
import { HookAPI } from 'antd/es/modal/useModal';

const pageData: IPageData = {
  title: 'Citas',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Citas'
    }
  ]
};

const ConfirmRemove = async (
  modal: HookAPI, appointment: IAppointment, appointments: IAppointment[], selectedAppointment: IAppointment, setAppointments: (data: IAppointment[]) => void
) => {
  const config = GetConfigModal(appointment)
  const confirmed = await modal.confirm(config);

  if (confirmed) {
    RemoveAppointment(appointment, (result) => {
      if (result > 0) {
        appointment.inUse = 0;
        const editedAppointments = appointments.map((el) => el !== selectedAppointment ? el : appointment);
        setAppointments(editedAppointments);

        openNotification('Eliminado!', "Se eliminó la cita");
      } else {
        openNotification('¡Error!', "Ocurrió un error al guardar", 'error');
      }
    });

  }
}

function GetConfigModal(appt: IAppointment) {
  return {
    title: 'Eliminar Cita!',
    content: (
      <>
        {`Deseas eliminar la cita para: ${appt._patient.name + ' ' + (appt._patient.lastname ? appt._patient.lastname : '')}, de la fecha: ${appt.forDate.split(' ')[0]} ?`}
      </>
    ),
  };
}


const AppointmentsPage = () => {
  usePageData(pageData);
  LoadPatients();

  const [appointments, setAppointments] = LoadAppointments();
  const [searchValue, setSearchValue] = useState('');

  var filterList: IAppointment[];
  if (searchValue === "") filterList = appointments;
  else filterList = appointments.filter(o => {
    return (o._patient && o._patient.name.toLowerCase().includes(searchValue.toLowerCase()) || (o._patient && o._patient.lastname && o._patient.lastname.toLowerCase().includes(searchValue.toLowerCase())));
  });

  pageData.OnChangeSearch = (res) => {
    setSearchValue(res);
  }

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [addingModalVisibility, setAddingModalVisibility] = useState(false);
  const history = useNavigate();

  const handleDelete = (ev: React.MouseEvent<HTMLElement, MouseEvent>, appointment: IAppointment) => {
    ev.stopPropagation();

    ConfirmRemove(_modal, appointment, appointments, selectedAppointment, setAppointments);
  };

  const handleEdit = (appointment: IAppointment) => {
    UpdateAppointment(appointment, (result) => {
      if (result > 0) {
        const editedAppointments = appointments.map((el) => el !== selectedAppointment ? el : appointment);
        setAppointments(editedAppointments);
        setSelectedAppointment(null);

        openNotification('¡Actualizado!', "Se actualizó la cita");
      } else {
        openNotification('¡Error!', "Ocurrió un error al guardar", 'error');
      }
    });
  };

  const openAddingModal = () => setAddingModalVisibility(true);

  const addAppointment = (appointment: IAppointment) => {
    CreateAppointment(appointment, (result) => {
      if (result > 0) {
        appointment.id = result;
        setAppointments([appointment, ...appointments]);
        setAddingModalVisibility(false);

        openNotification('¡Agregado!', "Se agregó una nueva cita");
      } else {
        openNotification('¡Error!', "Ocurrió un error al guardar", 'error');
      }
    })
  };

  const closeAddingModal = () => setAddingModalVisibility(false);

  const openEditModal = (ev: React.MouseEvent<HTMLElement, MouseEvent>, appointment: IAppointment) => {
    ev.stopPropagation();

    if (!appointment._patient || Object.keys(appointment._patient).length == 0)
      return;

    setSelectedAppointment(appointment)
  };

  const closeModal = () => {
    setSelectedAppointment(null);
  };

  const appointmentsActions = (appointment: IAppointment) => (
    <div className='buttons-list nowrap'>
      <Button onClick={(ev) => openEditModal(ev, appointment)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={(ev) => handleDelete(ev, appointment)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div >
  );

  const OnRowSelected = (appointment: IAppointment) => {
    history("/vertical/apointment-detail", { state: appointment });
  }

  //Modal config
  const [_modal, contextHolder] = Modal.useModal();

  return (
    <>
      <AppointmentsTable data={filterList} actions={appointmentsActions} OnRowSelected={OnRowSelected} />

      <PageAction onClick={openAddingModal} icon='icofont-stethoscope-alt' type={'primary'} />

      <AddAppointment
        onClose={closeAddingModal}
        visible={addingModalVisibility}
        onSubmit={addAppointment}
      />

      <EditAppointment
        appointment={selectedAppointment}
        visible={!!selectedAppointment}
        onClose={closeModal}
        onEdited={handleEdit}
      />

      {contextHolder}
    </>
  );
};

export default AppointmentsPage;
