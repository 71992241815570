import { ReactNode } from 'react';

import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
import { IAppointment } from '../../interfaces/patient';

const columns: ColumnProps<IAppointment>[] = [
  {
    key: 'name',
    dataIndex: '_patient',
    title: 'Nombre',
    className: 'cursor-pointer',
    sorter: (a, b) => a._patient && b._patient ? (a._patient.name > b._patient.name ? 1 : -1) : 1,
    render: (patient) => <strong>{patient ? patient.name : ' - '}</strong>
  },
  {
    key: 'email',
    dataIndex: '_patient',
    title: 'Email',
    sorter: (a, b) => a._patient && b._patient ? (a._patient.email > b._patient.email ? 1 : -1) : 1,
    render: (patient) => (
      <span className='nowrap' style={{ color: '#336cfb' }}>
        {patient?.email}
      </span>
    )
  },
  {
    key: 'phone',
    dataIndex: '_patient',
    title: 'Telefono',
    render: (patient) => (
      <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
        <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
        {patient?.phone}
      </span>
    )
  },
  {
    key: 'forDate',
    dataIndex: 'forDate',
    sorter: (a, b) => (new Date(a.forDate).getTime() - new Date(b.forDate).getTime()),
    title: 'Fecha cita',
    render: (date) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {date}
      </span>
    )
  },
  {
    key: 'painType',
    title: 'Tipo de dolor',
    render: (appointment) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {appointment.painType == 1 ? 'Espalda' : (appointment.painType == 2 ? 'Cuello' : 'Ambos')}
      </span>
    )
  }
];

type Props = {
  data: IAppointment[];
  actions?: (appointment: IAppointment) => ReactNode;
  OnRowSelected: (appointment: IAppointment) => void;
};

const AppointmentsTable = ({ data, actions, OnRowSelected }: Props) => {
  const actionColumn: ColumnProps<IAppointment> = {
    key: 'actions',
    title: 'Acciones',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;


  return (
    <Table
      rowKey='id'
      dataSource={data}
      columns={displayedColumns}
      onRow={(record) => {
        return {
          onClick: _ => OnRowSelected(record)
        };
      }}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default AppointmentsTable;
