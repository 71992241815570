import { Button, Card, Form, Input, Timeline } from 'antd';

import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../../pages/appointments/Appointments.scss';
import { IAppointment, mapPainType } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import { mapPatients } from '../../../data/Patient';
import DisabilityTable from '../../../pages/appointments/DisabilityTable';
import { GetQuestionByCategory, LoadQuestion } from '../../Model/Question';
import { EArrPainTempName, IQuestion } from '../../interfaces/Pain';
import SvgBodyPain, { SvgStringToSvgArrows } from '../../../pages/history/svgBodyPain';
import ScalePain from '../pain-patient/scale-pain';
import { SaveFormularioPDF } from './CreateFormularioPDF';
import { select } from 'd3';
import { LoadExpedientes } from '../../../data/Expediente';
import OpenNotification from '../../../pages/Toast';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
// import PDFViewerTest from '../../../data/TestPDF';


type PropsQuestion = {
  question: Array<IQuestion>
  type: 'CUELLO' | 'ESPALDA'
}

const pageData: IPageData = {
  title: 'Detalle del formulario',
  fulFilled: true,
  hasBack: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'home'
    },
    {
      title: 'Formulario',
      route: 'formularios'
    },
    {
      title: 'Detalle del formulario'
    }
  ]
};

const FormItem = Form.Item;

const ProfileForm = (
  { appointment }: { appointment: IAppointment; }
) => {
  const { values } = useFormik({
    initialValues: { ...appointment },
    onSubmit: () => null
  });


  const patnt = values._patient;
  let fullname = patnt.name + " " + (patnt.lastname ? patnt.lastname : "");
  const student = values._user || { name: '' };

  const typePainDescr = !!values.painType ? mapPainType.get(values.painType) : 'SOAP';


  return (
    <Form layout='vertical'>
      <div className='row mb-4'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Nombre completo'>
            <Input defaultValue={fullname} placeholder='nombre completo' disabled className={'ant-input-disabled'} />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Correo electrónico'>
            <Input defaultValue={values._patient.email} placeholder='email' disabled />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Tipo de dolor'>
            <Input defaultValue={typePainDescr} placeholder='Tipo de dolor' disabled />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Fecha de creación'>
            <Input defaultValue={values.createdAt} placeholder='Fecha de creación' disabled />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Creado por'>
            <Input defaultValue={student.name} placeholder='Nombre alumno' disabled />
          </FormItem>
        </div>
      </div>
    </Form>
  );
};

const PainMapContainer = ({ svg, isFront, tempObj }: { svg: string, isFront: boolean, tempObj: any }) => {
  const _id = isFront ? EArrPainTempName.SVG_PAIN_FRONT : EArrPainTempName.SVG_PAIN_BACK;
  tempObj[_id] = SvgStringToSvgArrows(svg);
  tempObj[_id] = tempObj[_id].map(item => select(item))

  return (
    <SvgBodyPain imgPath={isFront ? 'content/body_front.svg' : 'content/body_back.svg'}
      imgIdentifier={_id} objRefArrowsList={tempObj} isEnabled={false} btnColor={{} as any} />
  );
}

const QuestionList = ({ question, type }: PropsQuestion) => {

  let totalPoint = 0;
  question.map(item => {
    totalPoint += (item.selectedValue || 0);//Score es la posición
  });

  const timeQuestions = (<Timeline>
    {
      question.map((item, i) => (
        <Timeline.Item color='gray' key={i}>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            {item.title}
          </div>
          <span className='primary-text mb-4'>
            <strong>{item.answers[item.selectedValue]}</strong>
          </span>
        </Timeline.Item>
      ))
    }
  </Timeline>);

  return <>
    {timeQuestions}
    <h5 style={{ 'marginBottom': '0px' }}>El valor total es de {totalPoint}, su nivel de capacidad es "{DisabilityLevel(totalPoint)}".</h5>
    {type == 'ESPALDA' && (
      <h5 style={{ 'marginTop': '0px' }}>El porcentaje de discapacidad es {Math.round(totalPoint / 50 * 100)}%.</h5>
    )}
  </>
}

const DisabilityLevel = (score: number) => {
  if (score >= 0 && score <= 4)
    return 'Ninguna incapacidad';

  if (score >= 5 && score <= 14)
    return 'Incapacidad leve';

  if (score >= 15 && score <= 24)
    return 'Incapacidad moderada';

  if (score >= 25 && score <= 34)
    return 'Incapacidad severa';

  if (score >= 35 && score <= 50)
    return 'Incapacidad total';

  return '';
}

const FormularioDetail = () => {
  usePageData(pageData);

  LoadQuestion();
  const location = useLocation();
  const appointment = location.state as IAppointment;

  let history = useNavigate();

  if (!appointment) {
    history(-1);
    return (<></>);
  }

  const [_questionBack] = useState(GetQuestionByCategory(1));
  const [_questionNeck] = useState(GetQuestionByCategory(2));

  if (appointment.questions && appointment.questions.length > 0) {
    appointment.questions.forEach(item => {
      const fnd = _questionBack.find(o => o.id == item.id);
      if (fnd) fnd.selectedValue = item.value;
      const fnd2 = _questionNeck.find(o => o.id == item.id);
      if (fnd2) fnd2.selectedValue = item.value;
    });
  }

  const questionBack = _questionBack.filter(p => !!p.selectedValue);
  const questionNeck = _questionNeck.filter(p => !!p.selectedValue);

  if (!appointment._patient) appointment._patient = mapPatients.get(appointment.idPatient);

  const [expedienteList, _] = LoadExpedientes({ idValoracion: appointment.id });
  const expediente = expedienteList.length > 0 ? expedienteList[0] : null;

  const OnPDFSave = () => {
    SaveFormularioPDF(appointment);
  }

  const OnShowHistory = () => {
    if (!expediente) {
      OpenNotification('Expediente no encontrado', 'Este formulario no tiene un expediente valido', 'warn');
      return;
    }

    if (expediente.formComplete)
      history(`/vertical/history-expedientepage`, { state: [appointment._patient, expediente] });
    else
      history(`/vertical/history-neurologia`, { state: { patient: appointment._patient, expediente: expediente } });
  }

  const tempObj = {};

  return (
    <>

      {/* <PDFViewerTest OPT='FORMULARIO_STUDENT' data={appointment}></PDFViewerTest> */}

      <div className='info stack'>
        <ProfileForm appointment={appointment} />
      </div>

      <div className='row mb-4'>
        <div className='col-md-6 col-sm-12'>
          {appointment.svgPainMap1 && (
            <Card title='Mapa de dolor de frente' className='mb-0 svg-pain-map' >
              <PainMapContainer svg={appointment.svgPainMap1} isFront={true} tempObj={tempObj} />
            </Card>
          )}
        </div>

        <div className='col-md-6 col-sm-12'>
          {appointment.svgPainMap2 && (
            <Card title='Mapa de dolor de espalda' className='mb-0 svg-pain-map' >
              <PainMapContainer svg={appointment.svgPainMap2} isFront={false} tempObj={tempObj} />
            </Card>
          )}
        </div>
      </div>

      {
        questionBack.length > 0 && (
          <Card title='Cuestionario dolor de espalda' className='mb-20'>
            <QuestionList question={questionBack} type='ESPALDA' />
            <DisabilityTable type='BACK' />
          </Card>
        )
      }

      {
        questionNeck.length > 0 && (
          <Card title='Cuestionario dolor de cuello' className='mb-0'>
            <QuestionList question={questionNeck} type='CUELLO' />
            <DisabilityTable type='NECK' />
          </Card>)
      }

      {appointment.scalePain > -1 &&
        <Card title='Escala de dolor' className='mb-0 svg-pain-map' >
          <ScalePain objRef={{ 'scalePain': appointment.scalePain }} isEnabled={false} />
        </Card>
      }

      {(appointment.subjetivos || appointment.objsAprecs || appointment.planes) &&
        <Card title='SOAP' className='mb-0 svg-pain-map' >
          <Form layout='vertical'>
            <div className='row mb-4'>
              <div className='col-md-8 col-sm-12'>
                <FormItem label='Subjetivos'>
                  <TextArea defaultValue={appointment.subjetivos} placeholder='Subjetivos' disabled className={'ant-input-disabled'} />
                </FormItem>
              </div>
              <div className='col-md-8 col-sm-12'>
                <FormItem label='Objetivos apreciativos'>
                  <TextArea defaultValue={appointment.objsAprecs} placeholder='Objetivos apreciativos' disabled />
                </FormItem>
              </div>
              <div className='col-md-8 col-sm-12'>
                <FormItem label='Planes'>
                  <TextArea defaultValue={appointment.planes} placeholder='Planes' disabled />
                </FormItem>
              </div>
            </div>
          </Form>
        </Card>
      }

      {
        <div className='elem-list justify-content-between' style={{ 'marginTop': "15px" }}>
          <Button ghost danger className='ml-' onClick={OnPDFSave}>
            Imprimir
          </Button>
          <Button type='primary' className='ml-auto' onClick={OnShowHistory}>
            Ver Expediente
          </Button>
        </div>
      }

    </>
  );
};

export default FormularioDetail;
