

import { Button, Select, Input, DatePicker, DatePickerProps } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../utils/hasError';

import { IPatient } from '../../interfaces/patient';
import moment from 'moment';

// const { TextArea } = Input;s
type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const defaultSubmitText = 'Agregar paciente';
const emptyPatient: IPatient = {
  id: 0,
  name: null,
  age: null,
  phone: null,
  gender: null,
  birthDate: null,
  email: null,
  idPhoto: 0,
  inUse: true
} as any;

const patientScheme = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  birthDate: Yup.string().required(),
  gender: Yup.string().required(),
});

const PatientForm = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      console.log("values", values)
      onSubmit(values);
      onCancel();
    }
  });

  const handleGenderSelect = (value) => setFieldValue('gender', value);

  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  // const hasChanged = Object.keys(values).some((key) => values[key] !== submitted[key]);
  const dateFormat = 'YYYY-MM-DD';
  const dateNow = moment().format(dateFormat);
  const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
    values.birthDate = dateString;
  };


  return (
    <>
      <form onSubmit={handleSubmit} onEnded={(ev) => console.log(ev)}>

        <div className='form-group'>
          <Input
            placeholder='Nombre'
            name='name'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.name}
            className={hasError('name')} />
        </div>

        <div className='form-group'>
          <Input
            placeholder='Apellidos'
            name='lastname'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.lastname}
            className={hasError('lastname')} />
        </div>

        <div className='form-group'>
          <Input
            placeholder='Correo electrónico'
            name='email'
            type='email'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.email}
            className={hasError('email')} />
        </div>

        <div className='form-group'>
          <Input
            placeholder='Teléfono'
            name='phone'
            type='number'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.phone}
          />
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <DatePicker
                aria-required
                name='birthDate'
                className={'ant-input ' + hasError('birthDate')}
                placeholder='Fecha de nacimiento'
                onChange={onChangeDate}
                defaultValue={values.birthDate ? moment(values.birthDate, dateFormat) : null as any}
                format={dateFormat}
                onBlur={handleBlur}
                disabledDate={d => !d || d.isAfter(dateNow)} />
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Select
                placeholder='Genero'
                defaultValue={values.gender === 1 ? 'Masculino' : (values.gender === 0 ? 'Femenino' : null)}
                onChange={handleGenderSelect}
                className={hasError('gender')}
                onBlur={() => setFieldTouched('gender')}>
                <Select.Option value={1}>Masculino</Select.Option>
                <Select.Option value={0}>Femenino</Select.Option>
              </Select>
              {/* onChange={handleSelectChange('gender')} */}
            </div>
          </div>
        </div>

        {/* <div className='form-group'>
          <TextArea
            rows={3}
            name='description'
            placeholder='Descripción'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.description}
            className={hasError('description')}
          />
        </div> */}

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancelar
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default PatientForm;
