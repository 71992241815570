import jsPDF from "jspdf";
import { STR_IMG_SCALE_EMOGI, STR_IMG_SCALE_VALUES } from "../APPValDiscapacidad/Model/SvgImagePain";


export async function AddScalePainImageInPDF(
    pdf: jsPDF, widthContent: number, heightIcon: number, startX: number, posY: number, valScalePain: number, colorFont: string,
): Promise<number> {
    const svgScaleImg = AddSvgStringToImg(STR_IMG_SCALE_EMOGI);
    await AddImageToPDF(pdf, svgScaleImg, { width: widthContent, height: heightIcon, x: startX, y: posY })

    //Agregar valor escala dolor:
    posY += 60;
    LineScalePain(pdf, posY, valScalePain, startX, "#c9c9c9", widthContent, colorFont);

    posY += 20;
    const svgScaleImgValues = AddSvgStringToImg(STR_IMG_SCALE_VALUES);
    await AddImageToPDF(pdf, svgScaleImgValues, { width: widthContent, height: 50, x: startX, y: posY })

    return posY + 50;
}

export async function AddImageToPDF(pdf: jsPDF, iconSvg: Element, output: { width: number, height: number, x: number, y: number }) {
    let { width, height } = { width: 1080, height: 1080 }

    let outerHTML = iconSvg.outerHTML;
    // const uriData = 'data:image/svg+xml;base64,' + window.btoa(outerHTML);
    let blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
    let URL = window.URL || window.webkitURL;
    let blobURL = URL.createObjectURL(blob);

    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            const canvas = document.createElement("canvas");
            [canvas.width, canvas.height] = [width, height];

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            const svgData = canvas.toDataURL();

            pdf.addImage(svgData, 'PNG', output.x, output.y, output.width, output.height);

            resolve(svgData);
        };

        image.onerror = (ev) => {
            console.log(ev);
            reject()
        }

        image.src = blobURL;
        // image.src = uriData;
    });
}

export function AddSvgStringToImg(innerSvg: string) {
    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    iconSvg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
    iconSvg.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink")
    iconSvg.setAttribute('fill', '#FFF');
    iconSvg.setAttribute('viewBox', '0 0 1135 140');
    iconSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    iconSvg.setAttribute('pointerEvents', 'none');

    iconSvg.innerHTML = innerSvg;

    return iconSvg;
}


export function LineScalePain(
    pdf: jsPDF, posY: number, valSelected: number, _margin: number = 25, _colorGray: string = "#c9c9c9", _width: number = 380, _colorFont: string = "#000"
) {
    const mrgn = _margin * 1.5;
    // pdf.setDrawColor("#cccccc");
    pdf.setDrawColor(_colorGray);
    pdf.setTextColor(_colorGray);
    pdf.setFillColor(_colorGray);
    pdf.line(mrgn, posY, _width, posY, 'F');

    const totWidth = _width - mrgn;
    const sec10 = totWidth / 10;
    for (let i = 0; i < 11; i++) {
        if (valSelected == i) {
            pdf.setDrawColor(_colorFont);
            pdf.setTextColor(_colorFont);
            pdf.setFillColor(_colorFont);
        }

        let posx = mrgn + (sec10 * i)
        pdf.circle(posx, posY, 3, 'F');

        pdf.text("" + i, posx, posY + 12, { maxWidth: 20, align: 'center' });

        if (valSelected == i) {//Resetear
            pdf.setDrawColor(_colorGray);
            pdf.setTextColor(_colorGray);
            pdf.setFillColor(_colorGray);
        }
    }


}