import { useNavigate } from "react-router-dom"
import { IPatient } from "../../interfaces/patient"
import { ColumnProps } from 'antd/es/table';
import { Button, Table } from 'antd';
import { DismissLoading, ShowLoading, useHideLoader } from "../../hooks/useHideLoader";
import { LoadExpediente, SetCurrentExpediente } from "../../data/Expediente";
import { SetExpedienteQuestions } from "./ExpedienteQuestions";
import OpenNotification from "../Toast";
import { IExpediente } from "../../interfaces/expediente";
import { GetShortDescriptiveDate } from "../../data/General";

type props = {
    patients: IPatient[]
    OnSelectRow: (patient: IPatient) => void
}

const HistoryTable = ({
    patients,
}: props) => {

    useHideLoader()
    const history = useNavigate();

    const handleRowSelected = (patient: IPatient) => {
        const layout = location.hash.split('/')[1];
        LoadExpedienteInfo(patient, (exp: IExpediente) => {
            history(`/${layout}/history-expedientepage`, { state: [patient, exp] });
        });
    };

    const handleListExp = (patient: IPatient, evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
        const layout = location.hash.split('/')[1];
        history(`/${layout}/history-expedientelist`, { state: patient });
    }

    const columns: ColumnProps<IPatient>[] = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Nombre',
            sorter: (a, b) => (a.name > b.name ? 1 : -1),
            render: (name, item) => <strong>{name + " " + (item.lastname ? item.lastname : "")}</strong>
        },
        {
            key: 'gender',
            dataIndex: 'gender',
            title: 'Genero',
            render: (id) => (
                <span className='nowrap' style={{ color: '#a5a5a5' }}>
                    {id === 1 ? 'Masculino' : (id === 0 ? 'Femenino' : null)}
                </span>
            )
        },
        {
            key: 'birthDate',
            dataIndex: 'birthDate',
            title: 'Fecha de nacimiento',
            render: (birth) => (
                <span className='nowrap' style={{ color: '#a5a5a5' }}>
                    {GetShortDescriptiveDate(birth)}
                </span>
            )
        },
        {
            key: 'age',
            dataIndex: 'age',
            title: 'Edad',
            sorter: (a, b) => a.age - b.age,
            render: (age) => (
                <span className='nowrap' style={{ color: '#a5a5a5' }}>
                    {age} años
                </span>
            )
        },
        {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: 'Paciente desde',
            render: (visit) => (
                <span className='nowrap' style={{ color: '#a5a5a5' }}>
                    {new Date(visit).toLocaleDateString()}
                </span>
            )
        },
        {
            key: 'actions',
            title: 'Expedientes',
            render: (opt) => (
                <Button onClick={(evt) => handleListExp(opt, evt)} shape='round' type='primary'>
                    <span className='icofont icofont-list' />
                </Button>
            )
        }
    ];

    const pagination = !patients || patients.length <= 10 ? false : {};

    return (
        <>
            <Table
                pagination={pagination}
                className='accent-header'
                rowKey='id'
                dataSource={patients}
                onRow={(record, _) => {
                    return {
                        onClick: _ => handleRowSelected(record)
                    };
                }}
                columns={columns} />
        </>
    );

}

function LoadExpedienteInfo(patient: IPatient, successCompletion: (exp: IExpediente) => void) {
    ShowLoading();


    LoadExpediente(patient.id, async (result, success) => {
        DismissLoading();

        if (success < 1) {
            OpenNotification('¡Error de conexion!', "Ocurrió un error al descargar la informacion del paciente, asegurate de tener conexion a internet", 'error');
            return;
        }

        const _expedienteInfo = SetExpedienteQuestions(result, patient, false);
        await SetCurrentExpediente(_expedienteInfo)

        successCompletion(result);
    });
}




export default HistoryTable;