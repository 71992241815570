import { APP_IDENTIFIER } from "../interfaces/app-state";
import { ETypeUser, IUser } from "../interfaces/user";

export const _API = import.meta.env.APP_URL_API;
export const _VERSION = import.meta.env.APP_VERSION;
export const _DATABASE_NAME = import.meta.env.APP_DB_NAME;
export const _APP_ID = import.meta.env.APP_ID;

export const IsValidEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export var USER_INFO: IUser;

export const SetUserInfo = (info: IUser) => USER_INFO = info;

export const SetProfileImage = (img: string): string => {
    if (!img) return `${window.location.origin}/content/ic_profile.svg`;

    const isData = img.startsWith('data:image');
    const isWithPath = img.startsWith('http');

    if (isData || isWithPath)
        return img;

    return `${window.location.origin}/${img}`;//Esto aun no esta listo
};

// Date format
const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
const mounths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Nomviembre', 'Diciembre'];

export const GetDescriptiveDate = (strDate: string) => {
    let dt = new Date(strDate);
    let day = dt.getDay();
    let mth = dt.getMonth();

    return `${days[day]}, ${dt.getUTCDate()} ${mounths[mth]} ${dt.getFullYear()}`
}

export const GetShortDescriptiveDate = (strDate: string) => {
    let dt = new Date(strDate);
    // let day = dt.getDay();
    let mth = dt.getMonth();

    return `${mounths[mth]} ${dt.getUTCDate()}, ${dt.getFullYear()}`
}

export function FormatDateToHour(dt: Date | null): string {
    if (!dt || isNaN(dt.getTime())) return '';

    let hours = dt.getHours();
    const min = dt.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return hours.toString().padStart(2, "0") + ':' + min + ' ' + ampm;
}

export function FormatDateTo24Hour(dt: Date | null): string {
    if (!dt || isNaN(dt.getTime())) return '';

    let hours = dt.getHours();
    const min = dt.getMinutes().toString().padStart(2, "0");

    return hours.toString().padStart(2, "0") + ':' + min;
}

export function GetAppBuildTag() {
    return import.meta.env.APP_VERSION + "/" + import.meta.env.APP_DEBUG_VERSION + "/" + process.env.APP_TAG
        + (import.meta.env.MODE == "production" ? "" : ` (${import.meta.env.MODE})`);
}

export function ValAppValues() {
    return "====================" + "\n" +
        import.meta.env.APP_TITLE + "\n"
        + import.meta.env.APP_VERSION + "\n"
        + import.meta.env.APP_DEBUG_VERSION + "\n"
        + import.meta.env.APP_DB_NAME + "\n"
        + import.meta.env.APP_URL_API + "\n"
        + import.meta.env.MODE + "\n"
        + "===================="
}

export const accountDrop = [
    { id: 1, text: 'Editar cuenta', icon: 'icofont-ui-home', route: '/vertical/edit-account' },
    { id: 2, text: 'Perfil', icon: 'icofont-ui-user', route: '/vertical/user-profile' },
    { id: 3, text: 'Calendario', icon: 'icofont-ui-calendar', route: '/vertical/events-calendar' },
    // { text: 'Settings', icon: 'icofont-ui-settings', route: '/vertical/settings' },
    { id: -100, text: 'Cerrar sesión', icon: 'icofont-logout', route: 'home' }
];

export const ShowOptionAddPatient = () => {
    return (_APP_ID == APP_IDENTIFIER.DR_ADMIN || (_APP_ID == APP_IDENTIFIER.APP_DISCAPACIDAD && USER_INFO && USER_INFO.typeUser == ETypeUser.STUDENT));
}

export const GetScalePainDescription = (val: number): string => {
    if (val == 0) return "Sin dolor";
    if (val > 0 && val < 4) return "Dolor leve";
    if (val > 3 && val < 7) return "Dolor moderado";
    if (val > 6 && val < 10) return "Dolor severo";
    if (val >= 10) return "Dolor insoportable";
}

export const IsHistoryDisabled = (): boolean => {
    return (_APP_ID == APP_IDENTIFIER.APP_DISCAPACIDAD && USER_INFO.typeUser != ETypeUser.STUDENT)
}

export const HistoryRedirect = () => {
    return _APP_ID == APP_IDENTIFIER.APP_DISCAPACIDAD ? '/vertical/formularios' : '/vertical/history-table';
}

export const ShowMapPainDraw = () => {
    return _APP_ID != APP_IDENTIFIER.APP_DISCAPACIDAD;
}