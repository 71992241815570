import { Button, DatePicker, DatePickerProps, Input, Select } from 'antd';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../utils/hasError';

import { IAppointment, IPatient } from '../../interfaces/patient';
import TextArea from 'antd/lib/input/TextArea';
import { LoadPatients } from '../../data/Patient';
import moment from 'moment';

type Props = {
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
  toEdit?: boolean;
};

const defaultSubmitText = 'Agregar cita';
const emptyAppointment: IAppointment = {
  id: 0,
  idPatient: 0,
  forDate: "",
  painType: 0,
  svgPainMap1: "",
  svgPainMap2: "",
  status: 0,
  inUse: 1,
  description: "",
  _patient: {} as IPatient
} as any;

const appointmentSchema = Yup.object().shape({
  // _patient: Yup.string().required(),
  forDate: Yup.string().required(),
  painType: Yup.string().required(),
  description: Yup.string().required()
});

const AppointmentForm = ({
  submitText = defaultSubmitText,
  appointment = emptyAppointment,
  onSubmit,
  onCancel,
  toEdit
}: Props) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    isValid,
    errors,
    touched,
    resetForm
  } = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: (form) => {
      onSubmit(form);
      // resetForm();
    }
  });

  const [patientsResult] = LoadPatients();
  let patientsList: { value: string, id: number }[] = [];
  patientsResult.forEach(item => {
    patientsList.push({ value: item.name, id: item.id });
  })

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const hasError = hasErrorFactory(touched, errors);
  const handleStatusSelect = (value) => setFieldValue('painType', +value);

  const dateFormat = 'YYYY-MM-DD';
  const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
    values.forDate = dateString;
  };

  let dateApointment = values.forDate ? moment(values.forDate, dateFormat) : null as any;
  let minimum = moment().format(dateFormat);

  const handlePatientSelect = (value, _) => setFieldValue('idPatient', value);

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <>
      <form onSubmit={handleSubmit}>

        <div className='form-group'>
          {toEdit && <Input
            name='Patient'
            placeholder='Paciente'
            defaultValue={values._patient ? values._patient.name : null}
            disabled={true}
            className={hasError('_patient')}
          />}
          {/* {!toEdit && <AutoComplete
            filterOption
            options={patientsList}
            placeholder='Patient'
            onSelect={handlePatientSelect}
            onDeselect={handlePatientOther}
            defaultValue={values._patient ? values._patient.name : null} />
          } */}

          {!toEdit && <Select
            showSearch
            className='mb-lg-0 mb-4'
            placeholder='Selecciona un paciente'
            optionFilterProp='children'
            onSelect={handlePatientSelect}
            filterOption={optionFilter}>
            {
              patientsList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.value}
                </Select.Option>
              ))
            }
          </Select>
          }


        </div>

        <div className='form-group'>
          <DatePicker
            name='date'
            className='ant-input'
            placeholder='Fecha de la cita'
            onChange={onChangeDate}
            defaultValue={dateApointment}
            format={dateFormat}
            disabledDate={d => !d || d.isBefore(minimum)} />

        </div>

        <div className='form-group'>
          <Select
            placeholder='Tipo de dolor'
            defaultValue={values.painType === 1 ? 'Espalda' : (values.painType === 2 ? 'Cuello' : (values.painType === 3 ? 'Ambos' : null))}
            onChange={handleStatusSelect}
            className={hasError('painType')}
          // onBlur={() => setFieldTouched('status')}
          >
            <Select.Option value={1}>Espalda</Select.Option>
            <Select.Option value={2}>Cuello</Select.Option>
            <Select.Option value={3}>Ambos</Select.Option>
          </Select>
        </div>

        <div className='form-group'>
          <TextArea
            rows={3}
            name='description'
            placeholder='Descripción'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.description}
            className={hasError('description')}
          />
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Cancelar
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AppointmentForm;
