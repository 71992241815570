import { IExpediente } from "../../interfaces/expediente";
import { IPatient } from "../../interfaces/patient";
import { GetIMC, GetIMCDescription } from "./ExpUtils";
import { EQuestionGroup } from "./IconPainEVA";

export type SectionQuestion = {
    id: number;
    title: string;
    subtitle?: string;
    rowInfo: EQuestionGroup[];
}

export const listExpPage1: SectionQuestion[] = [
    {
        id: 1, title: 'Antecedentes personales no patologicos', subtitle: '', rowInfo: [
            { title: 'Antecentes familiares: (DM, HTA, Cardiacas, Pulmonares, Cancer.Etc.)', questions: [{ id: 100, type: 'TEXT_AREA' }] },
            { title: 'Tabaquismo', questions: [{ id: 101, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 102, label: 'Cantidad', type: 'SHORT' }] },
            { title: 'Etilismo', questions: [{ id: 103, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 104, label: 'Cantidad', type: 'SHORT' }] },
            { title: 'Drogas', questions: [{ id: 105, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 106, label: 'Cuales y cantidad', type: 'SHORT' }] },
            { title: 'Medicamentos de uso frecuente', questions: [{ id: 107, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 108, label: 'Cuales y cantidad', type: 'SHORT' }] },
            { title: 'Peso y talla', questions: [{ id: 109, hintText: 'Peso (KG)', type: 'SHORT', inputType: 'number' }, { id: 110, hintText: 'Talla (CM)', type: 'SHORT', inputType: 'number' }, { id: 111, hintText: 'IMC', type: 'SHORT', inputType: 'number' }] },
            { title: 'Obesidad', questions: [{ id: 112, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 113, label: 'Grado', options: [{ id: 1, tag: 'I' }, { id: 2, tag: 'II' }, { id: 3, tag: 'III' }], type: 'RADIO' }] },
            { title: 'Estado fisico del paciente', questions: [{ id: 114, options: [{ id: 1, tag: 'Excelente' }, { id: 2, tag: 'Bueno' }, { id: 3, tag: 'Regular' }, { id: 4, tag: 'Malo' }, { id: 5, tag: 'Muy malo' }], type: 'RADIO' }] },
            { title: 'Práctica de ejercicio', questions: [{ id: 115, options: [{ id: 1, tag: 'Importante' }, { id: 2, tag: 'Regular' }, { id: 3, tag: 'Ocasional' }, { id: 4, tag: 'Rara' }, { id: 5, tag: 'Nunca' }], type: 'RADIO' }] },
            { title: 'Tipo de actividad física (ejercicio)', questions: [{ id: 116, type: 'TEXT_AREA' }] },
        ]
    },
    {
        id: 2, title: 'Antecedentes personales patológicos', rowInfo: [
            { title: 'Diabetes', questions: [{ id: 200, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 201, label: 'Dx y Tx', type: 'SHORT' }] },
            { title: 'Hipertensión', questions: [{ id: 202, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 203, label: 'Dx y Tx', type: 'SHORT' }] },
            { title: 'Alergías', questions: [{ id: 204, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 205, label: 'Describir', type: 'SHORT' }] },
            { title: 'Enfermedades', questions: [{ id: 206, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 207, hintText: '(Diabetes, Hipertensión arterial sistémica, Osteoartrosis, Osteoporosis, Cardiología, Nefrología, Neumología, Reumatología, otras)', type: 'TEXT_AREA' }] },
            { title: 'Traumáticos', questions: [{ id: 208, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 209, hintText: '', type: 'TEXT_AREA' }] },
            { title: 'Quirúrgicos', questions: [{ id: 210, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 211, hintText: '', type: 'TEXT_AREA' }] },
            { title: 'Quirúrgicos de columna', questions: [{ id: 212, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }] },
            { title: 'Segmento y numero de cirugías', questions: [{ id: 213, label: 'Cervical', type: 'SHORT' }, { id: 214, label: 'Torácico', type: 'SHORT', }, { id: 215, label: 'Lumbar', type: 'SHORT' }, { id: 216, label: 'Sacrococcígeo', type: 'SHORT' }] },
            { title: 'Diagnósticos de cirugías previas', questions: [{ id: 217, type: 'TEXT_AREA' }] },
            { title: 'Complicaciones', questions: [{ id: 218, type: 'TEXT_AREA' }] },
            { title: 'Resultado de cirugia', questions: [{ id: 219, type: 'TEXT_AREA' }] },
        ]
    },
    {
        id: 3, title: 'Padecimiento actual', rowInfo: [
            { title: 'Síntomas principales', questions: [{ id: 300, type: 'TEXT_AREA', hintText: "¿Dolor o molestia principal de su problema?" }] },
            { title: 'Fecha de síntomas', questions: [{ id: 301, label: '¿Cuando comenzó?', type: 'SHORT' }, { id: 302, label: "¿ A Tenido Agudización?", type: 'SHORT' }] },
            { title: '', questions: [{ id: 303, hintText: "¿A qué lo atribuye? (Accidente, esfuerzo físico, súbito, insidioso)", type: 'TEXT_AREA' }] },
            { title: 'Tiempo de evolución', questions: [{ id: 304, options: [{ id: 1, tag: "Agudo(-6 sem)" }, { id: 2, tag: "Subagudo(6-12 sem)" }, { id: 3, tag: "Crónico(+12 sem)" }, { id: 4, tag: "Crónico agudizado" }], type: 'RADIO' }] },
            { title: '', questions: [{ id: 305, label: 'Localización', type: 'SHORT' }, { id: 306, label: 'Tipo', type: 'SHORT' }, { id: 307, label: 'Irradiaciones', type: 'SHORT' }] },
            { title: 'Parestesias', questions: [{ id: 308, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }] },
            { title: 'Debilidad', questions: [{ id: 309, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 310, label: 'Local/Generalizada', type: 'SHORT' }] },
            { title: 'Acompañantes', questions: [{ id: 311, type: 'TEXT_AREA' }] },
            { title: 'Claudicación (Vascular, Neurógenea u Otras)', questions: [{ id: 312, type: 'TEXT_AREA' }] },
            { title: 'Evolución y estado actual', questions: [{ id: 313, hintText: '¿Describa su molestia?', type: 'TEXT_AREA' }] },
            { title: 'Situaciones o actividades que mejoran su molestia', questions: [{ id: 314, type: 'TEXT_AREA' }] },
            { title: '¿Qué incrementa su problema?', questions: [{ id: 315, type: 'TEXT_AREA' }] },
            { title: 'Molestia causa afectación de vida', questions: [{ id: 316, options: [{ id: 1, tag: 'Laboral' }, { id: 2, tag: 'Deportiva' }, { id: 3, tag: 'Social' }, { id: 4, tag: 'Sexual' }], type: 'RADIO' }] },
            {
                title: 'Predominio de dolor', questions: [
                    { id: 317, label: 'Axial (Cervical/Dorsal/Lumbar/Sacro)', type: 'TEXT_AREA' },
                    { id: 318, label: 'Extremidad(Superior/Inferior)', type: 'TEXT_AREA' },
                    { id: 319, label: 'Porcentaje(escala numerica del 1 al 10 en cada uno)', type: 'SCALE_ICON' },
                    { id: 320, label: 'Axial(C, D, L ,S significa cervial, Dorsal, Lumbar Sacro)', type: 'SCALE_ICON' },
                    { id: 321, label: 'Extremidad(Superior H,B,AB,M significa Hombro,Brazo,Antebrazo,Mano Inferior G,M,P,P significa Gluteo,Muslo,Pierna,Pie) ', type: 'SCALE_ICON' }
                ]
            },
            { title: 'Dolor', questions: [{ id: 322, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 323, label: 'Otro', type: 'SHORT' }] },
        ]
    },
    {
        id: 4, title: 'Tratamientos recibidos hasta el momento', rowInfo: [
            { title: 'AINES', questions: [{ id: 400, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }] },
            { title: 'AINES', questions: [{ id: 401, label: 'Cuales', type: 'SHORT' }, { id: 402, label: 'Tiempo', hintText: "Dias o semanas", type: 'SHORT' }] },
            { title: 'Faja ú Ortesis', questions: [{ id: 403, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 404, label: 'Tipo y Tiempo', type: 'SHORT' }] },
            { title: 'Terapia física', questions: [{ id: 405, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 406, label: 'Fisioterapia', options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }] },
            { title: 'Ejercicios', questions: [{ id: 407, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 408, label: 'No. De sesiones', type: 'SHORT' }] },
            { title: 'Infiltraciones', questions: [{ id: 409, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 409, label: 'Especifique tipos y fechas', type: 'TEXT_AREA' }] },
            { title: '¿Ha notado cambios?', questions: [{ id: 410, label: '(Mejoró, empeoró o sigue igual)', type: 'TEXT_AREA' }] },
            { title: '¿Le incapacita para su vida?', questions: [{ id: 411, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 412, label: 'En que grado', options: [{ id: 1, tag: 'Leve' }, { id: 2, tag: 'Moderado' }, { id: 3, tag: 'Severo' }], type: 'RADIO' }] },
            { title: '¿Tipo de actividad en que siente la afectación?', questions: [{ id: 412, options: [{ id: 1, tag: 'Laboral' }, { id: 2, tag: 'Social' }, { id: 3, tag: 'Deportiva' }, { id: 4, tag: 'Sexual' }], type: 'RADIO' }] },
            { title: 'Preocupación principal de su problema', questions: [{ id: 413, type: 'TEXT_AREA' }] },
        ]
    },
    {
        id: 5, title: 'Exploración física(ORTOPEDICA)', rowInfo: [
            { title: 'Marcha', questions: [{ id: 500, type: 'RADIO', options: [{ id: 1, tag: 'Normal' }, { id: 2, tag: 'Asistencia' }] }, { id: 501, hintText: 'Baston, Andador, Silla de rueda, etc.', type: 'SHORT' }] },
            // { title: 'Marcha', questions: [{ id: 502, label: 'Normal', type: 'SHORT' }, { id: 503, label: 'Anormal', type: 'SHORT' }, { id: 504, label: 'Incapacidad', type: 'SHORT' }] },
            // { title: '', questions: [{ id: 505, label: 'Asistida', type: 'SHORT' }, { id: 506, label: 'Antalgica', type: 'SHORT' }, { id: 507, label: 'Temblor', type: 'SHORT' }] },
            {
                title: 'Marcha', questions: [{
                    id: 502, type: 'RADIO', options: [{ id: 1, tag: 'Normal' }, { id: 2, tag: 'Anormal' }, { id: 3, tag: 'Incapacidad' },
                    { id: 4, tag: 'Asistida' }, { id: 5, tag: 'Antalgica' }, { id: 6, tag: 'Temblor' }]
                }]
            },
            { title: 'Arco de sustentación', questions: [{ id: 508, options: [{ id: 1, tag: 'Amplio' }, { id: 2, tag: 'Paresia' }, { id: 3, tag: 'Tredelenburg' }], type: "CHECK" }] },
            { title: '', questions: [{ id: 509, label: 'Otros', type: 'TEXT_AREA' }] },
            { title: 'Inspección y Palpación', questions: [{ id: 510, label: 'Alteraciones locales', type: 'TEXT_AREA' }, { id: 511, label: 'Deformidades', type: 'TEXT_AREA' }, { id: 512, label: 'Dolor a la palpación y localización', type: 'TEXT_AREA' }] },
            { title: 'Consistencias de tejido:( Hipotrofia, hipertrofia, contractura, hipertónico, hipotónico, atónico etc.)', questions: [{ id: 513, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }, { id: 514, type: 'TEXT_AREA' }] },
            { title: 'Movilidad en columna', questions: [{ id: 5115, options: [{ id: 1, tag: 'Normal' }, { id: 2, tag: 'Disminuída' }, { id: 3, tag: 'Hipermovil' }], type: 'RADIO' }] },
        ]
    },
    {
        id: 6, title: 'Arcos de movilidad', rowInfo: [
            { title: 'Dolorosos', questions: [{ id: 600, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }] },//, { id: 601, label: 'Cervical', type: 'SHORT' }
            { title: '', questions: [{ id: 602, options: [{ id: 1, tag: 'Torácica' }, { id: 2, tag: 'Lumbar' }, { id: 3, tag: 'Cervical' }], type: 'RADIO' }, { id: 603, options: [{ id: 1, tag: 'Sacro coccígea' }], type: 'CHECK' }] },
            { title: 'Limitación', questions: [{ id: 604, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' }] },
            { title: 'Flexión', questions: [{ id: 605, options: [{ id: 1, tag: 'Flexión' }, { id: 2, tag: 'Extensión' }, { id: 3, tag: 'Lateralización Der' }, { id: 4, tag: 'Lateralización izq' }, { id: 5, tag: 'Rotación Der' }, { id: 6, tag: 'Rotación Izq' }], type: 'CHECK' }] },
            // { title: 'Marcha talón/puntas', questions: [{ id: 606, label: 'Normal', type: 'SHORT' }, { id: 607, label: 'Anormal', type: 'SHORT' }] },
            { title: 'Marcha talón/puntas', questions: [{ id: 606, options: [{ id: 1, tag: 'Normal' }, { id: 2, tag: 'Anormal' }], type: 'RADIO' },] },
            { title: '', questions: [{ id: 608, label: 'Observaciones', type: 'TEXT_AREA' }] },
            { title: 'Dolor sentado', questions: [{ id: 609, options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' }] },
            {
                title: '', questions: [{ id: 610, label: 'Parado', options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' },
                { id: 611, label: 'Flexión', options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' }]
            },
            { title: 'Dificultad sentarse', questions: [{ id: 612, options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' }] },
            {
                title: '', questions: [{ id: 613, label: 'Acostado', options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' },
                { id: 614, label: 'Extensión', options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' }]
            },
            { title: 'Dificultad pararse', questions: [{ id: 615, options: [{ id: 1, tag: '+' }, { id: 2, tag: '++' }, { id: 3, tag: '+++' }], type: 'RADIO' }] },
            { title: 'Puntos dolorosos', questions: [{ id: 616, options: [{ id: 1, tag: 'Espinosas' }, { id: 2, tag: 'Facetas' }, { id: 3, tag: 'Paravertebrales' }], type: 'CHECK' }] },
            { title: 'Glúteo', questions: [{ id: 617, options: [{ id: 1, tag: 'Derecho' }, { id: 2, tag: 'Izquierdo' }], type: 'RADIO' }] },
            { title: 'Isquiotibiales Sacroilíaca', questions: [{ id: 618, options: [{ id: 1, tag: 'Derecho' }, { id: 2, tag: 'Izquierdo' }], type: 'RADIO' }] },
            { title: 'Trapecio Derecho', questions: [{ id: 619, hintText: 'Hombros, Brazo Antebrazo Mano', type: 'TEXT_AREA' }] },
            { title: 'Trapecio Izquierdo', questions: [{ id: 621, hintText: 'Hombros, Brazo Antebrazo Mano', type: 'TEXT_AREA' }] },
            // { title: 'Escalenos', questions: [{ id: 620, options: [{ id: 1, tag: 'Occipital' }, { id: 2, tag: 'Mastoides' }], type: 'RADIO' }] },
            { title: 'Escalenos Derecho', questions: [{ id: 622, options: [{ id: 1, tag: 'Occipital' }, { id: 2, tag: 'Mastoides' }], type: 'RADIO' }] },
            { title: 'Escalenos Izquierdo', questions: [{ id: 623, options: [{ id: 1, tag: 'Occipital' }, { id: 2, tag: 'Mastoides' }], type: 'RADIO' }] },
        ]
    },
];


export const listExpPage2: SectionQuestion[] = [
    {
        id: 1, title: "Escala de dolor", rowInfo: [
            {
                title: "Utilizando la misma escala, ¿cuál es el número de dolor habitual de cada región?", questions: [
                    { id: 1, label: "Espalda baja", inputType: 'number', type: 'SHORT' }, { id: 2, label: "Glúteo", type: 'SHORT', inputType: 'number' },
                    { id: 3, label: "Muslo y pierna", type: 'SHORT', inputType: 'number' }]
            },
            {
                title: '', questions: [{ id: 4, label: "Cuello (cervical)", type: 'SHORT', inputType: 'number' },
                { id: 5, label: "Omóplato", type: 'SHORT', inputType: 'number' }, { id: 6, label: "Brazo y mano", type: 'SHORT', inputType: 'number' }],
            }, {
                title: "¿Cuál es el dolor máximo y mínimo en un día común en cada región? (eje: 7-3, 8-2, etc)", questions:
                    [{ id: 8, label: "Espalda baja", type: 'SHORT', inputType: 'number' }, { id: 8, label: "Glúteo", type: 'SHORT', inputType: 'number' },
                    { id: 9, label: "Muslo y pierna", type: 'SHORT', inputType: 'number' }]
            },
            {
                questions: [{ id: 10, label: "Cuello (cervical)", type: 'SHORT', inputType: 'number' },
                { id: 11, label: "Omóplato", type: 'SHORT', inputType: 'number' }, { id: 12, label: "Brazo y mano", type: 'SHORT', inputType: 'number' }]
            }, {
                title: "De su dolor en general ¿Qué porcentaje pertenece a cada región?", questions:
                    [{ id: 13, label: "Espalda baja", type: 'SHORT', inputType: 'number' }, { id: 14, label: "Glúteo", type: 'SHORT', inputType: 'number' },
                    { id: 15, label: "Muslo y pierna", type: 'SHORT', inputType: 'number' }]
            }, {
                questions: [{ id: 16, label: "Cuello (cervical)", type: 'SHORT', inputType: 'number' },
                { id: 17, label: "Omóplato", type: 'SHORT', inputType: 'number' }, { id: 18, label: "Brazo y mano", type: 'SHORT', inputType: 'number' }]

            }
        ],
    }, {
        id: 2, title: "Pruebas especiales", rowInfo: [
            {
                title: "Prueba de elevación de la pierna extendida(Signo de lassegue)", questions: [
                    { id: 211, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' },
                    { id: 200, label: "Derecha", type: 'SHORT', inputType: 'number' }, { id: 201, label: "Izquierda", type: 'SHORT', inputType: 'number' }]
            },
            {
                title: "Prueba de elevación de la pierna extendida reforzada(Signo de bragard)", questions: [
                    { id: 212, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' },
                    { id: 202, label: "Derecha", type: 'SHORT', inputType: 'number' }, { id: 203, label: "Izquierda", type: 'SHORT', inputType: 'number' }]
            },
            {
                title: "Prueba de elevación de la pierna extendida contralateral(Signo de Frajersztajn)", questions: [
                    { id: 213, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' },
                    { id: 204, label: "Derecha", type: 'SHORT', inputType: 'number' }, { id: 205, label: "Izquierda", type: 'SHORT', inputType: 'number' }]
            },
            {
                title: "Prueba de Patrick", questions: [
                    { id: 214, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' },
                    { id: 206, label: "Derecha", type: 'SHORT', inputType: 'number' }, { id: 207, label: "Izquierda", type: 'SHORT', inputType: 'number' }]
            },
            {
                title: "Prueba de tensión femoral(Signo de O'Connell)", questions: [
                    { id: 215, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' },
                    { id: 208, label: "Derecha", type: 'SHORT', inputType: 'number' }, { id: 209, label: "Izquierda", type: 'SHORT', inputType: 'number' }]
            },
            {
                title: "Prueba de  Spurling", questions: [
                    { id: 216, options: [{ id: 1, tag: 'Si' }, { id: 2, tag: 'No' }], type: 'RADIO' },
                    { id: 210, label: "Derecha", type: 'SHORT', inputType: 'number' }, { id: 211, label: "Izquierda", type: 'SHORT', inputType: 'number' }]
            },//last id === 216 ====, if you add more starts after the last id
        ]
    }, {
        id: 3, title: "Neurología", subtitle: "Pruebas especiales", rowInfo: [
            {
                title: "Signo de Lhermitte", questions: [
                    { id: 320, options: [{ id: 1, tag: 'Positivo' }, { id: 2, tag: 'Negativo' }], type: 'RADIO' },
                    { id: 321, options: [{ id: 1, tag: 'Derecho' }, { id: 2, tag: 'Izquierdo' }, { id: 3, tag: 'Bilateral' }], type: 'RADIO' }]
            },
            {
                title: "Reflejo de Hoffman", questions: [
                    { id: 322, options: [{ id: 1, tag: 'Positivo' }, { id: 2, tag: 'Negativo' }], type: 'RADIO' },
                    { id: 323, options: [{ id: 1, tag: 'Derecho' }, { id: 2, tag: 'Izquierdo' }, { id: 3, tag: 'Bilateral' }], type: 'RADIO' }]
            },
            {
                title: "Signo de Tromner", questions: [
                    { id: 324, options: [{ id: 1, tag: 'Positivo' }, { id: 2, tag: 'Negativo' }], type: 'RADIO' },
                    { id: 325, options: [{ id: 1, tag: 'Derecho' }, { id: 2, tag: 'Izquierdo' }, { id: 3, tag: 'Bilateral' }], type: 'RADIO' }]
            },
            {
                title: "Reflejo de Babinski derecho", questions: [
                    { id: 326, options: [{ id: 1, tag: 'Positivo' }, { id: 2, tag: 'Negativo' }, { id: 3, tag: 'Otros' }], type: 'RADIO' },
                    { id: 327, options: [{ id: 1, tag: 'Derecho' }, { id: 2, tag: 'Izquierdo' }, { id: 3, tag: 'Bilateral' }], type: 'RADIO' }]
            },
            {
                title: "Afectación de nervios:", questions: [{ id: 309, label: "Sensitiva", type: 'SHORT' }, { id: 310, label: "Motora", type: 'SHORT' }, { id: 311, label: "Reflejos", type: 'SHORT' }]
            },
            { questions: [{ id: 312, label: "Hipotrofia muscular de territorio", type: 'SHORT' }] },
            {
                title: "Escala visual de dolor de los últimos 30 días (0-10)", questions: [
                    { id: 313, label: "Axial (dolor): Habitual", type: 'SHORT' }, { id: 314, label: "Máximo", type: 'SHORT' }, { id: 315, label: "Mínimo", type: 'SHORT' }]
            },
            {
                questions: [{ id: 316, label: "Radicular (dolor): Habitual", type: 'SHORT' }, { id: 317, label: "Máximo", type: 'SHORT' },
                { id: 318, label: "Mínimo", type: 'SHORT' }]
            },
            { title: 'Comentarios', questions: [{ id: 319, type: 'TEXT_AREA' }] },
            { title: 'Estudios de laboratorio', questions: [{ id: 319, type: 'TEXT_AREA' }] },
        ]
    }, {
        id: 4, title: "Neurología", subtitle: "Estudios de imagen", rowInfo: [
            { title: 'Hallazgos en rayos X', questions: [{ id: 400, type: 'TEXT_AREA' }] },
            { title: '', questions: [{ id: 401, options: [{ id: 1, tag: "TSC" }, { id: 2, tag: "RAC" }], type: 'CHECK' }] },
            { title: 'Hallazgos en resonancia y / o TAC', questions: [{ id: 402, type: 'TEXT_AREA' }] },
            { title: 'EMG, ENC y PESS', questions: [{ id: 403, type: 'TEXT_AREA' }] },
            { title: 'Observaciones', questions: [{ id: 404, type: 'TEXT_AREA' }] },
            { title: 'IDX', questions: [{ id: 405, type: 'TEXT_AREA' }] },
            { title: 'TX', questions: [{ id: 406, type: 'TEXT_AREA' }] },
            { title: 'Complicación', questions: [{ id: 407, type: 'TEXT_AREA' }] },
            { title: 'Pronóstico', questions: [{ id: 408, type: 'TEXT_AREA' }] },
        ]
    }
]

export const questionsMainProfile: EQuestionGroup[] = [
    {
        questions: [
            { id: 1, label: "Particular", type: 'SHORT' },
            { id: 2, label: "Institucional", type: 'SHORT' },
            { id: 3, label: "Registro", type: 'SHORT' },
        ]
    }, {
        questions: [
            { id: 4, label: "Lugar de nacimiento", type: 'SHORT' },
            { id: 5, label: "Residencia actual", type: 'SHORT' },
        ]
    }, {
        questions: [
            { id: 6, label: "Escolaridad", type: 'SHORT' },
            { id: 7, label: "Ocupación", type: 'SHORT' },
            { id: 8, label: "Actividad Recreativa", type: 'SHORT' },
        ]
    }, {
        questions: [
            {
                id: 9, label: "Estado civil", type: 'RADIO',
                options: [{ id: 1, tag: 'Soltero' }, { id: 2, tag: 'Casado' }, { id: 3, tag: 'Divorciado' },
                { id: 4, tag: 'Viudo' }, { id: 5, tag: 'Unión libre' }], value: -1
            },
            { id: 10, label: "Religion", type: 'SHORT' }
        ]
    }, {
        title: "Trabajo % predominio", questions: [
            { id: 11, label: "Sentado", type: 'SHORT', inputType: 'number' },
            { id: 12, label: "Manejando", type: 'SHORT', inputType: 'number' },
            { id: 13, label: "Cargando", type: 'SHORT', inputType: 'number' },
            { id: 14, label: "Caminando", type: 'SHORT', inputType: 'number' },
            { id: 15, label: "Parado", type: 'SHORT', inputType: 'number' }
        ]
    }
]

export function SetExpedienteQuestions(result: IExpediente, patient: IPatient, disabled: boolean): IExpediente {
    let _expedienteInfo = result || {} as any;
    _expedienteInfo.idPatient = patient.id;

    if (result == null) {
        _expedienteInfo = NewEmptyExpediente(patient);
    }

    questionsMainProfile.forEach(item => {
        item.questions.forEach(subItem => {
            const objTemp = _expedienteInfo.questionMain.find(o => o.id == subItem.id);
            subItem.value = objTemp?.value || "";
            subItem.disabled = disabled;
        })
    })

    listExpPage1.forEach(item => {
        item.rowInfo.forEach(subItem => {
            subItem.questions.forEach(questItem => {
                const fndTemp = _expedienteInfo.questionPage1.find(o => o.id == questItem.id);
                questItem.value = fndTemp?.value || "";
                questItem.disabled = disabled;

                //// ... Calcular IMC ...
                if (questItem.id == 109 && questItem.value) {
                    if (subItem.descriptionValue) return;

                    const imc = subItem.questions.find(o => o.id == 111);
                    if (!imc.value) {
                        var otherQuest = subItem.questions.find(o => o.id == 110);
                        if (otherQuest.value) {
                            imc.value = GetIMC(questItem, otherQuest);
                        }
                    }

                    if (imc.value) {
                        subItem.descriptionValue = GetIMCDescription(imc.value);
                    }
                }
            })
        })
    });

    listExpPage2.forEach(item => {
        item.rowInfo.forEach(subItem => {
            subItem.questions.forEach(questItem => {
                questItem.disabled = disabled;
                const fndTemp = _expedienteInfo.questionPage2.find(o => o.id == questItem.id);
                if (fndTemp) questItem.value = fndTemp?.value || "";
            })
        })
    });

    return _expedienteInfo;
}

export function NewEmptyExpediente(patient: IPatient): IExpediente {
    let _expedienteInfo = {} as any;
    _expedienteInfo.idPatient = patient.id;

    _expedienteInfo.questionMain = [];
    _expedienteInfo.questionPage1 = [];
    _expedienteInfo.questionPage2 = [];
    _expedienteInfo.ISNCSCI = "";

    return _expedienteInfo;
}